import { Loader } from '@googlemaps/js-api-loader'

import Block from '../block'

export default class BlockGoogle extends Block {
	static title = 'block-google'

	constructor($el) {
		super($el)

		this.chart = this.$el.findElement('chart')

		const mapOptions = {
			center: {
				lat: parseFloat(this.$el.data('lat')),
				lng: parseFloat(this.$el.data('lng')),
			},
			zoom: 4,
		}

		new Loader({
			apiKey: 'AIzaSyAAa2JtezAnWENSSyBfh0Rovv3jeN5E-tg',
			version: 'beta',
		})
			.load()
			.then(async () => {
				new google.maps.Map(this.chart.get(0), mapOptions)
			})
	}
}
